import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import {
  childrenType
} from '../propTypes';

import { useSelector } from "react-redux";

import { getMsTeamsContext } from '../utils/getMsTeamsContext';

import CircularProgess from '../components/CircularProgress';


const propTypes = {
  children: childrenType,
  forceLoadingView: PropTypes.bool
}

export default function RedirectionManager({children, forceLoadingView = false}) {
  const auth = useSelector(state => state.auth);
  const [redirecting, setRedirecting] = useState(false);
  const [msTeamsContext, setMsTeamsContext] = useState(null);
  const currentPageLocation = useLocation();


  useEffect(() => {
    getMsTeamsContext({location: currentPageLocation, onContext: (context) => {
        // console.log('### msteams context', context);
        setMsTeamsContext(context);
      }
    });
  }, [currentPageLocation]);

  useEffect(() => {
    if (auth.targetAccountUrl) {
      // alert('\\\\\\ RedirectionManager > REDIRECT TO account URL: ' + auth.targetAccountUrl);
      if (msTeamsContext) {
        window.close();
        return;
      }

      setRedirecting(true);
      window.location.replace(auth.targetAccountUrl);
    }
  }, [auth.targetAccountUrl, msTeamsContext]);

  if (!redirecting && !forceLoadingView) {
    return children;
  }

  return <CircularProgess fullPage/>;
}

RedirectionManager.propTypes = propTypes;
