import { combineReducers } from 'redux';
import auth from './auth';
import customization from './customization';
import register from './register';
import captcha from './captcha';

export default combineReducers({
  auth,
  customization,
  register,
  captcha,
})
