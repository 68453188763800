import React from 'react';

import { useSelector } from "react-redux";

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";

import BaseRoutedContainer from './containers/BaseRoutedContainer';

import UserAuthenticationChecker from './containers/UserAuthenticationChecker';
import RedirectionManager from './containers/RedirectionManager';
import ForgottenPassword from './pages/ForgottenPassword';
import UpdatePassword from './pages/UpdatePassword'
import AccessRequest from './pages/AccessRequest';
import Register from './pages/Register';
import SignIn from './pages/SignIn';
import SignInOIDC from './pages/SignInOIDC';
import SignedOut from './pages/SignedOut';

import SignInForMsTeams, { ASSOCIATION_MODE } from './pages/msteams/SignInForMsTeams';
import SignUpForMsTeams from './pages/msteams/SignUpForMsTeams';

import GenericError from './pages/GenericError';
import { getLanguage } from './utils/getLanguage';

import { cocoomSuccessColor, cocoomErrorColor } from './components/styles';

export default function App() {
  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);
  const language = getLanguage();

  React.useEffect(() => {
    // INTERCOM
    let userParams = {};
    if (auth.user) {
      userParams = {
        email: auth.user.email,
        name: auth.user.firstname,
        user_hash: auth.user.ref
      };
    }

    // Intercom start
    try {
      if (window && window.Intercom && window.INTERCOM_APP_ID) {
        window.Intercom('boot', {
          app_id: window.INTERCOM_APP_ID,
          ...userParams,
          language_override: language,
          hide_default_launcher: false,
          alignment: 'left'
        });
      }
    } catch (intercomError) {
      // Ignore the error
    }
  }, [auth.user, language]);

  function NoMatch() {
    let location = useLocation();
    return (
      <div>
        <h1>Page not found</h1>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    );
  }

  const theme = responsiveFontSizes(createMuiTheme({
    palette: {
      primary: customization.tonicColor,
      error: { main: cocoomErrorColor },
      success: { main: cocoomSuccessColor }
    },
    altFontFamily: '"Lato", sans-serif'
  }));

  function MsTeamsRoutes({match}) {
    return (
      <React.Fragment>
        <Route path={`${match.path}/associate`}><UserAuthenticationChecker noRedirection><SignInForMsTeams mode={ASSOCIATION_MODE}/></UserAuthenticationChecker></Route>
        <Route path={`${match.path}/signin`}><UserAuthenticationChecker><SignInForMsTeams /></UserAuthenticationChecker></Route>
        <Route path={`${match.path}/signup`}><SignUpForMsTeams /></Route>
      </React.Fragment>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <BaseRoutedContainer>
          <Switch>
            <Redirect from="/" to="/signin" exact />
            <Redirect from="/register" to="/signup" exact />

            <Route path="/signup"><Register /></Route>

            <Route path="/msteams" component={MsTeamsRoutes}/>

            <Route path="/signin"><UserAuthenticationChecker><SignIn /></UserAuthenticationChecker></Route>
            <Route path="/signedout"><UserAuthenticationChecker redirectIfAuthentified="/signin"><SignedOut /></UserAuthenticationChecker></Route>

            <Route path="/oidc"><RedirectionManager><SignInOIDC /></RedirectionManager></Route>

            <Route path="/forgottenPassword"><UserAuthenticationChecker redirectIfAuthentified="/signin"><ForgottenPassword /></UserAuthenticationChecker></Route>

            <Route path="/updatePassword"><UpdatePassword /></Route>

            <Route path="/accessRequest"><UserAuthenticationChecker><AccessRequest /></UserAuthenticationChecker></Route>

            <Route path="/error"><GenericError /></Route>

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </BaseRoutedContainer>
      </Router>
    </ThemeProvider>
  );
}
