import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  childrenType
} from '../propTypes';

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import ApiErrorAlert from '../components/ApiErrorAlert';
import RedirectionManager from './RedirectionManager';

import {
  CHECK_USER_AUTH_ACTION_ID,
  GET_OIDC_AUTHORIZATION_URL_ACTION_ID,
  SIGNIN_ACTION_ID,
  checkUserAuthentification,
  redirectToAccountUrl,
} from '../reducers/auth';


const propTypes = {
  children: childrenType,
  noRedirection: PropTypes.bool,
  redirectIfAuthentified: PropTypes.string
}


export default function UserAuthenticationChecker({children, noRedirection = false, redirectIfAuthentified = undefined}) {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMesssage, setErrorMessage] = useState(null);

  const checkingAuth = auth.action &&  !auth.userChecked && auth.actionState !== 'idle' && ((auth.action === CHECK_USER_AUTH_ACTION_ID  && auth.actionState === 'working') || auth.action === GET_OIDC_AUTHORIZATION_URL_ACTION_ID || auth.oidcAuthorizationUrl);

  useEffect(() => {
    if (!auth.userChecked) {
      let promptForCredentials = false;
      if (window.location.search.indexOf('prompt') > -1) {
        promptForCredentials = true;
      }

      dispatch(checkUserAuthentification(promptForCredentials));
    }
  }, [dispatch, auth.userChecked]);

  useEffect(() => {
    if (redirectIfAuthentified && auth.action === CHECK_USER_AUTH_ACTION_ID && auth.actionState === 'done') {
      history.push(redirectIfAuthentified);
      return;
    }
  }, [history, auth.action, auth.actionState, redirectIfAuthentified]);

  useEffect(() => {
    if (auth.action === CHECK_USER_AUTH_ACTION_ID && auth.actionState === 'done' && auth.user && auth.user.accounts && auth.user.accounts.length === 1) {
      dispatch(redirectToAccountUrl(auth.user.accounts[0].url));
    }
  }, [dispatch, auth.action, auth.actionState, auth.user]);

  useEffect(() => {
    if (auth.action === CHECK_USER_AUTH_ACTION_ID && auth.actionState === 'failed' && auth.actionError.statusCode !== 401) {
      setErrorMessage(<ApiErrorAlert statusCode={auth.actionError.statusCode} message={auth.actionError.text} body={auth.actionError.body}/>);
    }
  }, [auth.action, auth.actionState, auth.actionError]);

  useEffect(() => {
    if (auth.oidcAuthorizationUrl) {
      window.location.replace(auth.oidcAuthorizationUrl);
    }
  }, [auth.oidcAuthorizationUrl]);

  useEffect(() => {
    if (auth.action === SIGNIN_ACTION_ID && auth.actionState === 'done' && auth.user && auth.user.accounts && auth.user.accounts.length === 1) {
      dispatch(redirectToAccountUrl(auth.user.accounts[0].url));
    }
  }, [dispatch, auth.action, auth.actionState, auth.user]);


  if (noRedirection) {
    return (<>{errorMesssage || children}</>);
  }

  return (
    <RedirectionManager forceLoadingView={checkingAuth}>
      {(errorMesssage || children)}
    </RedirectionManager>
  )
}

UserAuthenticationChecker.propTypes = propTypes;
