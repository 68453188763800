/*
 * actions
 */
const SET_CAPTCHA_PUBLIC_KEY = '/cocoom/SET_CAPTCHA_PUBLIC_KEY';

/*
 * action creators
 */
export function setCaptchaPublicKey(publicKey) {
  return {type: SET_CAPTCHA_PUBLIC_KEY, publicKey};
}

/*
 * initial state
 */
const initialState = {
  captchaPublicKey: null,
}

/*
 * reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {

    ////
    //  SET_CAPTCHA_PUBLIC_KEY
    case SET_CAPTCHA_PUBLIC_KEY:
      const newState = (captchaPublicKey => {
        if (captchaPublicKey) {
          const res = {...state, captchaPublicKey};
          return res;
        }

        return {...state};
      })(action.publicKey);

      return newState;

    ////
    //  default
    default:
      return state;
  }
}
