
import createAsyncAction from '@cocoom/cocoom-front-utils/api/createAsyncAction';
import createAsyncActionCreator from '@cocoom/cocoom-front-utils/api/createAsyncActionCreator';
import superagent from 'superagent';

import { PASSWORD_UPDATE_PARAM } from '../constants';
import { adaptAuthentificationData } from './adapters/adaptAuthentificationData';

import queryString from 'query-string';

/*
 * actions
 */
export const ACCESS_REQUEST_ACTION_ID = '/cocoom/auth/ACCESS_REQUEST_ACTION_ID';
const ACCESS_REQUEST = createAsyncAction(ACCESS_REQUEST_ACTION_ID);

export const ASSOCIATION_REQUEST_ACTION_ID = '/cocoom/auth/ASSOCIATION_REQUEST_ACTION_ID';
const ASSOCIATION_REQUEST = createAsyncAction(ASSOCIATION_REQUEST_ACTION_ID);

export const CHECK_USER_AUTH_ACTION_ID = '/cocoom/auth/CHECK_USER_AUTH_ACTION_ID';
const CHECK_USER_AUTH = createAsyncAction(CHECK_USER_AUTH_ACTION_ID);

export const PASSWORD_CHECK_UPDATE_TOKEN_ACTION_ID = '/cocoom/auth/PASSWORD_CHECK_UPDATE_TOKEN_ACTION_ID';
const PASSWORD_CHECK_UPDATE_TOKEN = createAsyncAction(PASSWORD_CHECK_UPDATE_TOKEN_ACTION_ID);
export const PASSWORD_UPDATE_REQUEST_ACTION_ID = '/cocoom/auth/PASSWORD_UPDATE_REQUEST_ACTION_ID';
const PASSWORD_UPDATE_REQUEST = createAsyncAction(PASSWORD_UPDATE_REQUEST_ACTION_ID);
export const PASSWORD_RESET_REQUEST_ACTION_ID = '/cocoom/auth/PASSWORD_RESET_REQUEST_ACTION_ID';
const PASSWORD_RESET_REQUEST = createAsyncAction(PASSWORD_RESET_REQUEST_ACTION_ID);

const REDIRECT_TO_OIDC_IDP_AUTH_URL = '/cocoom/auth/REDIRECT_TO_OIDC_IDP_AUTH_URL';
const REDIRECT_TO_ACCOUNT_URL = '/cocoom/auth/REDIRECT_TO_ACCOUNT_URL';
const RESET_REDIRECT_TO_TARGET_ACCOUNT_URI = '/cocoom/auth/RESET_REDIRECT_TO_TARGET_ACCOUNT_URI';

const RESET_ACTION_STATE = '/cocoom/auth/RESET_ACTION_STATE';

const SET_COCOOM_ACCOUNT_URI = '/cocoom/auth/SET_COCOOM_ACCOUNT_URI';
const SET_COCOOM_AUTHENTICATION_INFO = '/cocoom/auth/SET_COCOOM_AUTHENTICATION_INFO';

export const SIGNIN_ACTION_ID = '/cocoom/auth/SIGNIN_ACTION_ID';
const SIGNIN = createAsyncAction(SIGNIN_ACTION_ID);

export const SIGNOUT_ACTION_ID = '/cocoom/auth/SIGNOUT_ACTION_ID';
const SIGNOUT = createAsyncAction(SIGNOUT_ACTION_ID);

export const GET_OIDC_AUTHORIZATION_URL_ACTION_ID = '/cocoom/auth/GET_OIDC_AUTHORIZATION_URL';
const GET_OIDC_AUTHORIZATION_URL = createAsyncAction(GET_OIDC_AUTHORIZATION_URL_ACTION_ID);


/*
 * action creators
 */
export function accessRequest({firstname, lastname, email, group, cocoomAccountUri}) {
  return createAsyncActionCreator(ACCESS_REQUEST, async () => {
    const result = await superagent.post(`/api/start/auth/access-request`).send({
      firstname,
      lastname,
      email,
      group,
      cocoomAccountUri
    });
    if (!result.body) {
      throw new Error('unexpected response payload');
    }
  });
}

export function associationRequest({email, cocoomAccountUri, tid, target}) {
  return createAsyncActionCreator(ASSOCIATION_REQUEST, async () => {
    const result =  await superagent.post(`/api/start/association-request/${target}`).send({
      email,
      cocoomAccountUri,
      azureTenantId: tid
    });
    if (!result.body) {
      throw new Error('unexpected response payload');
    }
  });
}

export function checkUserAuthentification(promptForCredentials = false) {
  return createAsyncActionCreator(CHECK_USER_AUTH, async () => {
    let queryPart = '';
    if (promptForCredentials) {
      queryPart = '?prompt=true';
    }
    const result = await superagent.get(`/api/start/auth/getauth${queryPart}`);
    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    return adaptAuthentificationData(result.body);
  });
}
export function passwordCheckUpdateToken() {
  return createAsyncActionCreator(PASSWORD_CHECK_UPDATE_TOKEN, async () => {
    const urlParams = queryString.parse(window.location.search);
    const token = urlParams[PASSWORD_UPDATE_PARAM];
    const result = await superagent.get(`/api/start/auth/password-reset/check?token=${token}`);
    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    return result.body;
  });
}

export function passwordUpdateRequest(password, resetId) {
  return createAsyncActionCreator(PASSWORD_UPDATE_REQUEST, async () => {
    const result = await superagent.post(`/api/start/auth/password-reset/update`).send({
      password: password,
      resetId: resetId
    });
    if (!result.body) {
      throw new Error('unexpected response payload');
    }
    return result.body;
  });
}

export function passwordResetRequest(email) {
  return createAsyncActionCreator(PASSWORD_RESET_REQUEST, async () => {
    const result = await superagent.post(`/api/start/auth/password-reset/request`).send({
      email
    });
    if (!result.body) {
      throw new Error('unexpected response payload');
    }
    return result.body;
  });
}

export function redirectToAccountUrl(targetAccountUrl) {
  return {type: REDIRECT_TO_ACCOUNT_URL, targetAccountUrl};
}

export function resetRedirectionTargetAccountUrl() {
  return {type: RESET_REDIRECT_TO_TARGET_ACCOUNT_URI};
}

export function resetActionState() {
  return {type: RESET_ACTION_STATE};
}

export function setCocoomAccountUri(cocoomAccountUri) {
  return {type: SET_COCOOM_ACCOUNT_URI, cocoomAccountUri};
}


export function setCocoomAuthInfo(authMode) {
  return {type: SET_COCOOM_AUTHENTICATION_INFO, authMode};
}

export function signIn(credentials) {
  return createAsyncActionCreator(SIGNIN, async () => {
    // alert('Calling legacy sign in use case: ' + JSON.stringify(credentials.extraHeaders));
    const result = await superagent.post(`/api/start/auth/signin`).send({
      auth: 'legacy',
      email: credentials.email,
      password: credentials.password
    }).set(credentials.extraHeaders || {});

    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    return adaptAuthentificationData(result.body);
  });
}

export function signInOIDC(code, state, extraHeaders) {
  return createAsyncActionCreator(SIGNIN, async () => {
    // alert('Calling sign in for OIDC use case: ' + JSON.stringify(extraHeaders));
    const result = await superagent.post(`/api/start/auth/signin`).send({
      auth: 'oidc',
      code: code,
      state: state
    }).set(extraHeaders || {});

    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    return adaptAuthentificationData(result.body);
  });
}

export function signOut() {
  return createAsyncActionCreator(SIGNOUT, async () => {
    const result = await superagent.get(`/api/start/auth/signout`);
    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    // Intercom shutdown
    try {
      if (window && window.Intercom) {
        window.Intercom('shutdown');
      }
    } catch (intercomError) {
      // Ignore the error
    }

    return result.body;
  });
}


// OIDC specific
// Get the requested account OIDC authorization url
export function getOIDCAuthorizationUrl(extraHeaders, promptForCredentials, oidcProviderType) {
  return createAsyncActionCreator(GET_OIDC_AUTHORIZATION_URL, async () => {
    const result = await superagent.get(`/api/start/auth/getoidcauthorizationurl?type=${oidcProviderType}&forceCredentials=${promptForCredentials}`).send().set(extraHeaders || {});

    if (!result.body) {
      throw new Error('unexpected response payload');
    }

    return result.body;
  });
}


/*
 * initial state
 */
const initialState = {
  action: null,
  actionError: null,
  actionState: 'idle',

  cocoomAccountUri: null,

  targetUrl: null,
  targetAccountUrl: null,

  user: null,
  userChecked: false,

  passwordResetRequestAccepted: null,
  passwordResetId: null,

  authMode: 'legacy'
}

/*
 * reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {

    ////
    // ACCESS_REQUEST
    case ACCESS_REQUEST.PENDING:
      return {
        ...state,
        action: ACCESS_REQUEST_ACTION_ID,
        actionState: 'working',
        actionError: null
      }
    case ACCESS_REQUEST.DONE:
      return {
        ...state,
        actionState: 'done'
      }
    case ACCESS_REQUEST.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed'
      }

    ////
    // ASSOCIATION_REQUEST
    case ASSOCIATION_REQUEST.PENDING:
      return {
        ...state,
        action: ASSOCIATION_REQUEST_ACTION_ID,
        actionState: 'working',
        actionError: null
      }
    case ASSOCIATION_REQUEST.DONE:
      return {
        ...state,
        actionState: 'done'
      }
    case ASSOCIATION_REQUEST.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed'
      }

    ////
    // CHECK_USER_AUTH
    case CHECK_USER_AUTH.PENDING:
      return {
        ...state,
        action: CHECK_USER_AUTH_ACTION_ID,
        actionState: 'working',
        actionError: null,
        user: null,
        userChecked: true
      }
    case CHECK_USER_AUTH.DONE:
      return {
        ...state,
        actionState: 'done',
        user: action.user,
        userChecked: true
      }
    case CHECK_USER_AUTH.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed',
        userChecked: true
      }

    ////
    // PASSWORD_CHECK_UPDATE_TOKEN
    case PASSWORD_CHECK_UPDATE_TOKEN.PENDING:
      return {
        ...state,
        action: PASSWORD_CHECK_UPDATE_TOKEN_ACTION_ID,
        actionState: 'working',
        actionError: null,
        passwordResetId: null
      }
    case PASSWORD_CHECK_UPDATE_TOKEN.DONE:
      return {
        ...state,
        actionState: 'done',
        passwordResetId: action.resetId
      }
    case PASSWORD_CHECK_UPDATE_TOKEN.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed',
        passwordResetId: null
      }

    ////
    // PASSWORD_UPDATE_REQUEST
    case PASSWORD_UPDATE_REQUEST.PENDING:
      return {
        ...state,
        action: PASSWORD_UPDATE_REQUEST_ACTION_ID,
        actionState: 'working',
        actionError: null,
        passwordResetId: null
      }
    case PASSWORD_UPDATE_REQUEST.DONE:
      return {
        ...state,
        actionState: 'done'
      }
    case PASSWORD_UPDATE_REQUEST.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed'
      }

    ////
    // PASSWORD_RESET_REQUEST
    case PASSWORD_RESET_REQUEST.PENDING:
      return {
        ...state,
        action: PASSWORD_RESET_REQUEST_ACTION_ID,
        actionState: 'working',
        actionError: null,
        passwordResetRequestAccepted: null
      }
    case PASSWORD_RESET_REQUEST.DONE:
      return {
        ...state,
        actionState: 'done',
        passwordResetRequestAccepted: action.status === 'ok'
      }
    case PASSWORD_RESET_REQUEST.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed',
        passwordResetRequestAccepted: null
      }

    case REDIRECT_TO_ACCOUNT_URL:
      return {
        ...state,
        targetAccountUrl: action.targetAccountUrl
      };


    ////
    case RESET_ACTION_STATE:
      return {
        ...state,
        action: null,
        actionError: null,
        actionState: 'idle',
      };

    ////
    case SET_COCOOM_ACCOUNT_URI:
      if (action.cocoomAccountUri && action.cocoomAccountUri !== '%COCOOM_ACCOUNT_URI%') {
        return {
          ...state,
          cocoomAccountUri: action.cocoomAccountUri
        };
      }

      return state;

    case RESET_REDIRECT_TO_TARGET_ACCOUNT_URI:
      return {
        ...state,
        targetAccountUrl: initialState.targetAccountUrl
      };

    case SET_COCOOM_AUTHENTICATION_INFO:
      if (action.authMode) {
        return {
          ...state,
          authMode: action.authMode
        };
      }

      return state;

    ////
    // SIGNIN
    case SIGNIN.PENDING:
      return {
        ...state,
        action: SIGNIN_ACTION_ID,
        actionState: 'working',
        actionError: null,
        user: null,
        userChecked: true
      }
    case SIGNIN.DONE:
      return {
        ...state,
        actionState: 'done',
        user: action.user
      }
    case SIGNIN.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed'
      }

    ////
    // SIGNOUT
    case SIGNOUT.PENDING:
      return {
        ...state,
        action: SIGNOUT_ACTION_ID,
        actionState: 'working',
        actionError: null
      }
    case SIGNOUT.DONE:
      return {
        ...state,
        actionState: 'done',
        user: null,
        userChecked: false
      }
    case SIGNOUT.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed',
        userChecked: false
      }


    // OIDC Get authorization url
    case GET_OIDC_AUTHORIZATION_URL.PENDING:
      return {
        ...state,
        actionState: 'working',
        actionError: null,
        oidcAuthorizationUrl: null
      };
    case GET_OIDC_AUTHORIZATION_URL.DONE:
      // console.log('Reducer | GET_OIDC_AUTHORIZATION_URL.DONE auth url = ' + action.authorizationUrl);
      return {
        ...state,
        actionState: 'done',
        oidcAuthorizationUrl: action.authorizationUrl
      };
    case GET_OIDC_AUTHORIZATION_URL.FAILED:
      return {
        ...state,
        actionError: action.error,
        actionState: 'failed',
        oidcAuthorizationUrl: null
      };


    ////
    //  default
    default:
      return state;
  }
}
