import {MEDIA_PATTERN} from '../constants';


/*
 * actions
 */
const SET_CUSTOMIZATION = '/cocoom/SET_CUSTOMIZATION';
const SET_COCOOM_CDN_ENDPOINT_URI = '/cocoom/auth/SET_COCOOM_CDN_ENDPOINT_URI';


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}


/*
 * action creators
 */
export function setCustomization(customization, cdnEndpointUrl) {
  return {type: SET_CUSTOMIZATION, customization, cdnEndpointUrl};
}

export function setCDNEndpointUri(cdnEndpointUri) {
  return {type: SET_COCOOM_CDN_ENDPOINT_URI, cdnEndpointUri};
}


/*
 * initial state
 */
const initialState = {
  accessRequest: {
    title: {
      fr: 'Pas de compte utilisateur ?',
      en: 'No user account ?'
    },
    subtitle: {
      fr: 'Demandez un accès en utilisant le formulaire ci-dessous.',
      en: 'Ask for an access using the form below.'
    }/* ,
    group: {
      title: {
        fr: 'Équipes',
        en: 'Departments'
      },
      items: [
        { fr: 'Ressources humaines', en: 'Human Resources'},
        { fr: 'Recherche et Développement', en: 'Research & Developpment'},
        { fr: 'Vente', en: 'Sales'},
        { fr: 'Achat', en: 'Purchase'},
      ]
    } */
  },
  accountSelector: {
    title: {
      fr: 'Sélectionnez votre base de connaissances',
      en: 'Select your knowledge base'
    },
    subtitle: {
      fr: 'Votre compte utilisateur vous donne accès à plusieurs bases de connaissances Cocoom.',
      en: 'Your user account gives you access to several Cocoom knowledge bases'
    }
  },
  // backgroundColor: 'deeppink',
  backgroundImage: 'linear-gradient(0deg, rgb(237, 243, 252) 32%, rgb(255, 255, 255) 58%)',
  // backgroundImage: null,
  forgottenPassword: {
    title: {
      fr: 'Mot de passe oublié ?',
      en: 'Forgotten password ?'
    },
    subtitle: {
      fr: 'Nous vous envoyons un lien de réinitialisation par e-mail.',
      en: 'We email you a reset link.'
    }
  },
  updatePassword: {
    title: {},
    subtitle: {}
  },
  signIn: {
    title: {
      // img: '###CC-STATIC_RESRC###/logos/pepites-shaker/logo_light_bg.png',
      imgAlt: 'Logo',
      // fr: 'Content de vous voir !',
      fr: 'Bienvenue',
      en: 'Welcome'
    },
    subtitle: {
      fr: 'Votre base de connaissance vous attend...',
      en: 'You\'re at only one step away from your knowledge base...'
    }
  },
  /* tonicColor: {
    main: '#ABCDEF',
    light: '#D1E3F6',
    dark: '#6AA7E3'
  }, */
  topLogo: '###CC-STATIC_RESRC###/start/logo_cocoom.png',
  topLogoAlt: 'Cocoom',
  topLogoInPanel: true,

  cdnEndpointUri: 'https://static.cocoom.com'
}

/*
 * reducer
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {

    ////
    //  SET_CUSTOMIZATION
    case SET_CUSTOMIZATION:
      const newState = (customization => {
        if (customization) {
          const res = {...state, ...customization};
          for (const key of Object.keys(action.customization)) {
            switch(key) {
              case 'accountSelector':
                res.accountSelector = {...state.accountSelector, ...customization.accountSelector};
                break;
              case 'forgottenPassword':
                res.forgottenPassword = {...state.forgottenPassword, ...customization.forgottenPassword};
                break;
              case 'accessRequest':
                res.accessRequest = {...state.accessRequest, ...customization.accessRequest};
                break;
              case 'signIn':
                res.signIn = {...state.signIn, ...customization.signIn};
                break;
              default:
                break;
            }
          }
          return res;
        }

        return {...state};
      })(action.customization);

      if (Array.isArray(newState.backgroundImage)) {
        newState.backgroundImage = newState.backgroundImage[getRandomInt(newState.backgroundImage.length)];
      }

      const stringifiedNewState = JSON.stringify(newState);
      const re = new RegExp(MEDIA_PATTERN,"g");
      const newsState = JSON.parse(stringifiedNewState.replace(re, action.cdnEndpointUrl));
      newState.cdnEndpointUri = action.cdnEndpointUrl;
      return newsState;


    /////
    case SET_COCOOM_CDN_ENDPOINT_URI:
      return {
        ...state,
        cdnEndpointUri: action.cdnEndpointUri
      };


    ////
    //  default
    default:
      return state;
  }
}
