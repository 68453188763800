import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import {validateEmail} from '../reducers/validators/registerForm';

import AlignableImage from '../components/AlignableImage';
import ApiErrorAlert from '../components/ApiErrorAlert';
import Button from '../components/Button';
import CenteredPanelPage from '../components/CenteredPanelPage';
import LanguageSelector from '../components/LanguageSelector';
import TextField from '../components/TextField';
import Typography from '../components/Typography';

import {
  PASSWORD_RESET_REQUEST_ACTION_ID,
  passwordResetRequest,
  resetActionState
} from '../reducers/auth';
import { getMsTeamsContext, getMsTeamsOrStandalonePath } from '../utils/getMsTeamsContext';
import { getLanguage } from '../utils/getLanguage';
import * as cookie from '../utils/cookieManager';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { verifyCaptchaToken } from '../utils/handleCaptcha';


export default function ForgottenPassword() {
  const [tr, i18n] = useTranslation();
  const language = getLanguage();
  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);
  const [userEmail, setUserEmail] = useState('');
  const [userEmailError, setUserEmailOnError] = useState(null);
  const [msTeamsContext, setMsTeamsContext] = useState(null);
  const [isCaptachaTokenVerified, setCaptchaTokenVerified] = useState(false);

  const STEP_INTIAL_FORM = 'initial-form';
  const STEP_SEND_SUCCESSFUL = 'send-ok';
  const STEP_SEND_FAILED = 'send-failed';
  const STEP_CANNOT_RESET_PASSWORD = 'cannot-reset'

  let currentStep = STEP_INTIAL_FORM;
  if (auth.action === PASSWORD_RESET_REQUEST_ACTION_ID) {
    switch(auth.actionState) {
      case 'idle':
      case 'working':
        currentStep = STEP_INTIAL_FORM;
        break;
      case 'done':
        if (auth.passwordResetRequestAccepted) {
          currentStep = STEP_SEND_SUCCESSFUL;
        } else {
          currentStep = STEP_CANNOT_RESET_PASSWORD;
        }
        break;
      case 'failed':
        currentStep = STEP_SEND_FAILED
        break;
      default:
        currentStep = STEP_INTIAL_FORM;
        console.error('auth.actionState cannot be "' + + '". Must be idle, working, done or failed.');
        break;
    }
  }

  const working = auth.actionState === 'working';
  const dispatch = useDispatch();

  cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);


  useEffect(() => {
    dispatch(resetActionState());
  }, [dispatch]);


  useEffect(() => {
    const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);

    getMsTeamsContext({encodedData: msTeamsOIDCSigninCookie, onContext: (context) => {
      setMsTeamsContext(context);
    }});
  }, []);


  useEffect(() => {
    const listener = event => {
      if (userEmail && (event.code === "Enter" || event.code === "NumpadEnter")) {
        // console.log("Enter key was pressed. Run your function.");
        dispatch(passwordResetRequest(userEmail));
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [dispatch, userEmail]);

  function changeLanguage(language) {
    i18n.changeLanguage(language);
  };

  function getErrorAlert() {
    const statusCode = auth.actionError.statusCode;

    if (auth.action === PASSWORD_RESET_REQUEST_ACTION_ID) {
      return (
        <React.Fragment>
          <ApiErrorAlert statusCode={statusCode} message={auth.actionError.text} body={auth.actionError.body}/>
        </React.Fragment>
      );
    }

    return null;
  }

  function validateThenSetUserEmail(emailValue) {
    const emailError = validateEmail(emailValue);
    setUserEmailOnError(emailError);
    setUserEmail(emailValue);
  }

  function getReinitialisationForm() {
    return (
      <React.Fragment>
        <TextField
          id="userEmail"
          value={userEmail.value}
          autoFocus
          autocomplete="email"
          label={tr('signin.form.email.label')}
          helperText={tr('signin.form.email.helper')}
          errorText={userEmailError ? tr('forgottenPassword.error.' + userEmailError) : null}
          onChange={event => validateThenSetUserEmail(event.target.value)}
          fullWidth
        />
        <div style={{textAlign: 'center'}}>
          <Button id="reinitpwd" label={tr('forgottenPassword.reinitPassword')} onClick={() => dispatch(passwordResetRequest(userEmail))} loading={working} disabled={Boolean(!userEmail || Boolean(userEmailError) || !isCaptachaTokenVerified)}/>
        </div>
      </React.Fragment>
    );
  }

  function getUserConfirmationView() {
    return (
      <React.Fragment>
        <AlignableImage align="center" src="/send.svg" alt="Message sent" imgStyle={{width: 100, height: 100}}/>
        {<Typography align="center" altFont>{tr('forgottenPassword.mailSentMessage')}</Typography>}
      </React.Fragment>
    );
  }

  function getCannotResetAlert() {
    return (
      <React.Fragment>
        <AlignableImage align="center" src="/alert.svg" alt="Error" imgStyle={{width: 100, height: 100}}/>
        {<Typography align="center" altFont>{tr('forgottenPassword.cannotResetPassword')}</Typography>}
      </React.Fragment>
    );
  }

  function closeWindow() {
    const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
    cookie.resetCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
    cookie.resetCookie(cookie.MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME);

    if (msTeamsContext || msTeamsOIDCSigninCookie) {
      window.close();
      return null;
    }
  }

  const verifyCaptcha = useCallback(async (token) => {
    try {
      const verifiedToken = await verifyCaptchaToken(token);
      setCaptchaTokenVerified(!!verifiedToken);
    } catch (error) {
      setCaptchaTokenVerified(false);
    }
  }, []);

  return (
    <React.Fragment>
      <CenteredPanelPage
        backgroundColor={customization.backgroundColor}
        backgroundImage={customization.backgroundImage}
        headerBar={(
          <div style={{textAlign: 'right'}}>
            <LanguageSelector language={language} onChange={changeLanguage}/>
          </div>
        )}
        topLogo={customization.topLogo}
        topLogoAlt={customization.topLogoAlt}
        topLogoInPanel={customization.topLogoInPanel}
        title={customization.forgottenPassword.title[language]}
        imageAsTitle={customization.forgottenPassword.title.img}
        imageAsTitleAlt={customization.forgottenPassword.title.imgAlt}
        subtitle={currentStep === STEP_INTIAL_FORM && customization.forgottenPassword.subtitle[language]}
      >
        {currentStep === STEP_INTIAL_FORM && getReinitialisationForm()}
        {currentStep === STEP_SEND_SUCCESSFUL && getUserConfirmationView()}
        {currentStep === STEP_CANNOT_RESET_PASSWORD && getCannotResetAlert()}
        {currentStep === STEP_SEND_FAILED && getErrorAlert()}
        <div style={{textAlign: 'center'}}>
          {msTeamsContext &&
            <a href="." onClick={() => closeWindow()}>
              {tr('general.close')}
            </a>
          }

          {!msTeamsContext &&
            <a href={getMsTeamsOrStandalonePath('/signin' + (window.location.search ? window.location.search : ''), msTeamsContext)}>
              {tr('general.back')}
            </a>
          }
        </div>
      </CenteredPanelPage>
      <GoogleReCaptcha onVerify={verifyCaptcha} />
    </React.Fragment>
  );
}
