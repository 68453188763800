import queryString from 'query-string';

export function getMsTeamsContext({location = null, encodedData = null, onContext = null}) {
  let dataToDecode = encodedData;
  if (location && location.search) {
    const urlParams = queryString.parse(location.search);
    if (urlParams) {
      dataToDecode = urlParams.d;
      // alert('MS Teams context : ' + dataToDecode);
    }
  }

  if (dataToDecode) {
    const data = JSON.parse(decodeURIComponent(escape(atob(dataToDecode))));
    if (onContext) {
      onContext({
        tid: data.tid,
        userObjectId: data.uid,
        groupId: data.gid,
        loginHint: data.l,
        locale: data.lg
      });
    }
  }
}


export function getMsTeamsOrStandalonePath(relativePath, msTeamsContext) {
  if (msTeamsContext) {
    return '/msteams' + relativePath;
  }
  return relativePath;
}