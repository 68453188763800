import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import MuiCircularProgress from '@material-ui/core/CircularProgress';

import translateWithXsModeDistinction from '../utils/i18nWithXsModeDistinction';

import LanguageSelector from '../components/LanguageSelector';
import PageHeader from '../components/PageHeader';
import Page from '../components/Page';
import PageRow from '../components/PageRow';
import PageColumn from '../components/PageColumn';
import PageFooter from '../components/PageFooter';
import TextField from '../components/TextField';
import Select from '../components/Select';
import Checkbox from '../components/Checkbox';
import Button from '../components/Button';
import HorizontalSlidingSections from '../components/HorizontalSlidingSections';
import CircularProgress from '../components/CircularProgress';
import Snackbar from '../components/Snackbar';
import Typography from '../components/Typography';
import Zxcvbn from '../components/Zxcvbn';

import useWidth from '../hooks/useWidth';

import {
  setTitle,
  setDomain,
  setUsage,
  setLastname,
  setFirstname,
  setEmail,
  setPassword,
  setPasswordConfirmation,
  setNewsletter,
  setTerms,
  setLanguage,
  setPasswordStrengthFeedback,
  dismissActionError,
  validateStep,
  getBackToStep,

  CHECK_DOMAIN_ACTION,
  CREATE_DOMAIN_ACTION
} from '../reducers/register';

import * as cookie from '../utils/cookieManager';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { verifyCaptchaToken } from '../utils/handleCaptcha';

const propTypes = {
  noPassword: PropTypes.bool,
  noEditableEmail: PropTypes.bool,
  CustomConfirmationPage: PropTypes.func
};


export default function Register({noPassword = false, noEditableEmail = false, CustomConfirmationPage = null}) {
  const [tr, i18n] = useTranslation();
  const state = useSelector(state => state.register);
  const dispatch = useDispatch();
  const gridWidth = useWidth();
  const [isCaptachaTokenVerified, setCaptchaTokenVerified] = useState(false);

  const language = (i18n.language.length > 2) ? i18n.language.slice(0,2) : i18n.language;

  cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);

  useEffect(() => {
    dispatch(setUsage('generic'));
  }, [dispatch])

  // Enhanced translation function
  function t(id, context) {
    return translateWithXsModeDistinction(id, context, gridWidth, tr);
  }

  function changeLanguage(language) {
    i18n.changeLanguage(language);
  }

  function getDomainHelperText() {
    if (state.action === CHECK_DOMAIN_ACTION && state.actionState === 'working') {
      return <span><CircularProgress size={12} thickness={1} color="rgba(0, 0, 0, 0.54)"/> {t('register.form.domain.checkingAvailability')}</span>;
    }

    if (state.domain.available) {
      return t('register.form.domain.available');
    }

    return t('register.form.domain.helper');
  }

  function getPageTitle() {
    if (state.action === CREATE_DOMAIN_ACTION && state.actionState === 'working') {
      return t('register.creatingPlatform');
    }

    if (state.action === CREATE_DOMAIN_ACTION && state.actionState === 'done') {
      return t('register.platformReady');
    }

    return t('register.title');
  }

  function getPageSubtitle() {
    if (state.action === CREATE_DOMAIN_ACTION && state.actionState === 'working') {
      return undefined;
    }

    if (state.action === CREATE_DOMAIN_ACTION && state.actionState === 'done') {
      return undefined;
    }

    return t('register.subtitle');
  }

  function getErrorMessage() {
    if (state.actionState === 'failed') {
      // console.log('getErrorMessage()', state.actionError);
      if (state.action === CHECK_DOMAIN_ACTION) {
        return t('register.error.cannotCheckDomainAvailability') + '\n' + t('error.api', {code: state.actionError.statusCode, message: state.actionError.message});
      }

      if (state.action === CREATE_DOMAIN_ACTION) {
        return t('register.error.cannotCreatePlatform') + '\n' + t('error.api', {code: state.actionError.statusCode, message: state.actionError.message});
      }

      if (state.actionError) {
        if (!state.actionError.status) {
          return t('error.network.offline');
        }
      }
    }

    return '';
  }

  const verifyCaptcha = useCallback(async (token) => {
    try {
      const verifiedToken = await verifyCaptchaToken(token);
      setCaptchaTokenVerified(!!verifiedToken);
    } catch (error) {
      setCaptchaTokenVerified(false);
    }
  }, []);

  const fromSignIn = window.location.search.indexOf('?fsignin') >= 0;

  return (
    <React.Fragment>
      <Page maxWidth="md" fullHeight>
        <PageRow>
          <PageColumn style={{textAlign: 'right'}}>
            <LanguageSelector language={language} onChange={changeLanguage}/>
          </PageColumn>
        </PageRow>
        <PageRow>
          <PageHeader
            title={getPageTitle()}
            subtitle={getPageSubtitle()}
          />
        </PageRow>
        <PageRow nbColumnsXs={1}>
          <PageColumn style={{textAlign: 'center'}}>
            <HorizontalSlidingSections fullWidth currentSectionIndex={state.currentStep}>
              <div id="step1">
                <TextField
                  id="title"
                  autoFocus
                  value={state.title.value}
                  label={t('register.form.title.label')}
                  helperText={t('register.form.title.helper')}
                  errorText={Boolean(state.title.error) ? t('register.error.' + state.title.error, {maxLength: 80}) : null}
                  onChange={event => dispatch(setTitle(event.target.value))}
                  required
                  fullWidth
                />
                <TextField
                  id="domain"
                  value={state.domain.value}
                  label={t('register.form.domain.label')}
                  prefix="https://"
                  suffix=".cocoom.com"
                  helperText={getDomainHelperText()}
                  errorText={Boolean(state.domain.error) ? t('register.error.' + state.domain.error, {maxLength: 40}) : null}
                  valid={state.domain.available}
                  displayErrorOnChange
                  onChange={event => dispatch(setDomain(event.target.value))}
                  required
                  fullWidth
                />
                <div style={{display: gridWidth === 'xs' ? 'block' : 'flex'}}>
                  <Select
                    id="language"
                    label={t('register.form.language.label')}
                    helperText={t('register.form.language.helper')}
                    onChange={event => dispatch(setLanguage(event.target.value))}
                    value={state.language}
                    values={[
                      {value: 'fr', label: 'Français'},
                      {value: 'en', label: 'English'}
                    ]}
                  />
                </div>
                <Button id="continueToStep2" label={t('register.form.next.label')} disabled={!isCaptachaTokenVerified} onClick={() => dispatch(validateStep(0))}/>
              </div>


              <div id="step2">
                <Typography>{t('register.form.step1Title')}</Typography>
                <TextField
                  id="firstname"
                  autocomplete="given-name"
                  value={state.firstname.value}
                  width={gridWidth === 'xs' ? '100%' : '50%'}
                  label={t('register.form.firstname.label')}
                  errorText={Boolean(state.firstname.error) ? t('register.error.' + state.firstname.error) : null}
                  onChange={event => dispatch(setFirstname(event.target.value))}
                  required
                />
                <TextField
                  id="lastname"
                  autocomplete="family-name"
                  value={state.lastname.value}
                  width={gridWidth === 'xs' ? '100%' : '50%'}
                  label={t('register.form.lastname.label')}
                  errorText={Boolean(state.lastname.error) ? t('register.error.' + state.lastname.error) : null}
                  onChange={event => dispatch(setLastname(event.target.value))}
                  required
                />
                <TextField
                  id="email"
                  autocomplete="email"
                  value={state.email.value}
                  label={t('register.form.email.label')}
                  helperText={t('register.form.email.helper')}
                  errorText={Boolean(state.email.error) ? t('register.error.' + state.email.error) : null}
                  onChange={event => dispatch(setEmail(event.target.value))}
                  disabled={noEditableEmail}
                  required
                  fullWidth
                />
                {!noPassword && <React.Fragment>
                  <Zxcvbn password={state.password.value} onScore={(result) => dispatch(setPasswordStrengthFeedback(result.acceptable, result.feedback))} options={{language: language}}/>
                  <TextField
                    id="password"
                    value={state.password.value}
                    width={gridWidth === 'xs' ? '100%' : '50%'}
                    label={t('register.form.password.label')}
                    helperText={state.password.helper}
                    errorText={state.password.error}
                    displayErrorOnChange
                    onChange={event => dispatch(setPassword(event.target.value))}
                    required
                    password
                    autocomplete="new-password"
                  />
                  <TextField
                    id="passwordConfirmation"
                    value={state.passwordConfirmation.value}
                    width={gridWidth === 'xs' ? '100%' : '50%'}
                    label={t('register.form.passwordConfirmation.label')}
                    helperText={t('register.form.passwordConfirmation.helper')}
                    errorText={Boolean(state.passwordConfirmation.error) ? t('register.error.' + state.passwordConfirmation.error) : null}
                    displayErrorOnChange
                    onChange={event => dispatch(setPasswordConfirmation(event.target.value))}
                    required
                    password
                    autocomplete="new-password"
                  />
                </React.Fragment>}
                <Button id="backToStep1" label={t('register.form.previous.label')} onClick={() => dispatch(getBackToStep(0))} color="default" variant="outlined"/>
                <Button id="continueToStep3" label={t('register.form.next.label')} disabled={!isCaptachaTokenVerified} onClick={() => dispatch(validateStep(1, {noPassword}))}/>
              </div>


              <div id="step3">
                <div style={{textAlign: 'left'}}>
                  <Checkbox
                    id="newsletter"
                    small
                    label={t('register.form.newsletter.label')}
                    checked={state.newsletter}
                    onChange={event => dispatch(setNewsletter(event.target.checked))}
                  />
                  <Checkbox
                    id="terms"
                    checked={state.terms.value}
                    small
                    label={<span dangerouslySetInnerHTML={{
                      __html: tr('register.form.terms.label', {
                        interpolation: {escapeValue: false},
                        termsOfSale: `<a href="https://cocoom.com/terms-of-use/?lang=${language}" target="_blank">${t('register.form.termsOfSale.label')}</a>`,
                        privacyPolicy: `<a href="https://cocoom.com/privacy-policy/?lang=${language}" target="_blank">${t('register.form.privacyPolicy.label')}</a>`
                      })
                    }}/>}
                    errorText={Boolean(state.terms.error) ? t('register.error.' + state.terms.error) : null}
                    onChange={event => dispatch(setTerms(event.target.checked))}
                  />
                </div>
                <Button id="backToStep2" label={t('register.form.previous.label')} onClick={() => dispatch(getBackToStep(1))} color="default" variant="outlined"/>
                <Button id="create" label={t('register.form.create.label')} disabled={!isCaptachaTokenVerified} onClick={() => dispatch(validateStep(2))}/>
              </div>


              <div>
                {state.action === CREATE_DOMAIN_ACTION && state.actionState === 'working' && <div style={{textAlign: 'center'}}>
                  <MuiCircularProgress size={80}/>
                </div>}
                {state.action === CREATE_DOMAIN_ACTION && state.actionState === 'done' && !CustomConfirmationPage && <div style={{textAlign: 'center'}}>
                  <img src="/ready.png" width={(gridWidth === 'xs') ? '100%' : 273} alt=""/>
                  <Typography gutterBottom variant="h4">{t('register.allGood')}</Typography>
                  <Typography gutterBottom pre variant="h6">{t('register.emailSent', {email: state.email.value})}</Typography>
                  <Typography gutterBottom pre variant="h6">{t('register.needHelp')}</Typography>
                </div>}
                {state.action === CREATE_DOMAIN_ACTION && state.actionState === 'done' && CustomConfirmationPage && <CustomConfirmationPage gridWidth={gridWidth} />}
                {state.action === CREATE_DOMAIN_ACTION && state.actionState === 'failed' &&  <div style={{textAlign: 'center'}}></div>}
              </div>
            </HorizontalSlidingSections>
          </PageColumn>
        </PageRow>

        {fromSignIn && <PageRow>
          <PageColumn>

            <div style={{textAlign: 'center'}}>
            { /* We must use an absolute path URL because some server side execution need to be done; it can't be a client-side relative hyperlink (such as  <Link>) */}
            <a href="/signin">
              {tr('general.back')}
            </a>
          </div>

          </PageColumn>
        </PageRow>}
      </Page>

      <PageFooter />

      <Snackbar
        open={state.actionState === 'failed'}
        onClose={() => dispatch(dismissActionError())}
        message={getErrorMessage()}
      />
      <GoogleReCaptcha onVerify={verifyCaptcha} />
    </React.Fragment>
  );
}

Register.propTypes = propTypes;
