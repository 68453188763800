import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import AlignableImage from '../components/AlignableImage';
import ApiErrorAlert from '../components/ApiErrorAlert';
import Button from '../components/Button';
import CenteredPanelPage from '../components/CenteredPanelPage';
import CircularProgress from '../components/CircularProgress';
import LanguageSelector from '../components/LanguageSelector';
import Link from '../components/Link';
import TextField from '../components/TextField';
import Typography from '../components/Typography';
import Zxcvbn from '../components/Zxcvbn';
import {
  resetActionState,
  PASSWORD_CHECK_UPDATE_TOKEN_ACTION_ID,
  passwordCheckUpdateToken,
  PASSWORD_UPDATE_REQUEST_ACTION_ID,
  passwordUpdateRequest,
} from '../reducers/auth';

import { getLanguage } from '../utils/getLanguage';


export default function UpdatePassword() {
  const [tr, i18n] = useTranslation();
  const language = getLanguage();
  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordStrengthFeedback, setPasswordStrengthFeedback] = useState({acceptable: null, message: null});

  const STEP_CHECK_TOKEN = 'checking-token';
  const STEP_INVALID_TOKEN = 'invalid-token';
  const STEP_UPDATE_FORM = 'initial-form';
  const STEP_SUCCESSFUL_UPDATE = 'update-success';
  const STEP_INVALID_RESET_ID = 'invalid-reset-id';
  const STEP_HTTP_ERROR = 'http-error';

  const working = auth.actionState === 'working';
  let currentStep = STEP_CHECK_TOKEN;
  if (auth.action === PASSWORD_CHECK_UPDATE_TOKEN_ACTION_ID) {
    switch(auth.actionState) {
      case 'done':
        currentStep = STEP_UPDATE_FORM;
        break;
      case 'failed':
        if (auth.actionError.statusCode === 404) {
          currentStep = STEP_INVALID_TOKEN;
          break;
        }
        currentStep = STEP_HTTP_ERROR;
        break;
      default:
        currentStep = STEP_CHECK_TOKEN;
        break;
    }
  }

  if (auth.action === PASSWORD_UPDATE_REQUEST_ACTION_ID) {
    switch(auth.actionState) {
      case 'done':
        currentStep = STEP_SUCCESSFUL_UPDATE;
        break;
      case 'failed':
        if (auth.actionError.statusCode === 404) {
          currentStep = STEP_INVALID_RESET_ID;
          break;
        }
        currentStep = STEP_HTTP_ERROR;
        break;
      default:
        currentStep = STEP_UPDATE_FORM;
        break;
    }
  }


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetActionState());
    dispatch(passwordCheckUpdateToken());
  }, [dispatch]);

  function changeLanguage(language) {
    i18n.changeLanguage(language);
  };

  function getCheckingView() {
    return <div style={{textAlign: 'center'}}><CircularProgress /></div>
  }

  function getErrorAlert() {
    const statusCode = auth.actionError.statusCode;
    return (
      <React.Fragment>
        <ApiErrorAlert statusCode={statusCode} message={auth.actionError.text} body={auth.actionError.body}/>
        <div style={{textAlign: 'center'}}><Link to="/signIn">{tr('general.back')}</Link></div>
      </React.Fragment>
    );
  }

  function getUpdateForm() {
    return (
      <React.Fragment>
        <Zxcvbn password={password} onScore={(result) => setPasswordStrengthFeedback({acceptable: result.acceptable, message: result.feedback})} options={{language: language}}/>
        <TextField
          id="new-password-text-field"
          autocomplete="new-password"
          password
          value={password}
          autoFocus
          label={tr('updatePassword.password.label')}
          helperText={passwordStrengthFeedback.acceptable && passwordStrengthFeedback.message}
          errorText={!passwordStrengthFeedback.acceptable && passwordStrengthFeedback.message}
          displayErrorOnChange
          onChange={event => setPassword(event.target.value)}
          fullWidth
        />
        <TextField
          id="confirm-password-text-field"
          autocomplete="new-password"
          password
          value={passwordConfirmation}
          label={tr('updatePassword.passwordConfirmation.label')}
          helperText={tr('updatePassword.passwordConfirmation.helper')}
          errorText={password && passwordConfirmation && password !== passwordConfirmation ? tr('updatePassword.passwordsMismatchError') : null}
          onChange={event => setPasswordConfirmation(event.target.value)}
          fullWidth
        />
        <div style={{textAlign: 'center'}}>
          <Button id="updatepwd" label={tr('updatePassword.update')} disabled={!password || (password !== passwordConfirmation) || !passwordStrengthFeedback.acceptable} onClick={() => dispatch(passwordUpdateRequest(password, auth.passwordResetId))} loading={working}/>
        </div>
      </React.Fragment>
    );
  }

  function getUserConfirmationView() {
    return (
      <React.Fragment>
        <AlignableImage align="center" src="/validation-mark.svg" alt="operation succeed" imgStyle={{width: 50, height: 50}}/>
        {<Typography align="center" altFont>{tr('updatePassword.successMessage')}</Typography>}
        <div style={{textAlign: 'center'}}><Link to="/signIn">{tr('general.back')}</Link></div>
      </React.Fragment>
    );
  }

  function getInvalidTokenOrIdAlert() {
    return (
      <React.Fragment>
        <AlignableImage align="center" src="/alert.svg" alt="Error" imgStyle={{width: 100, height: 100}}/>
        {currentStep === STEP_INVALID_TOKEN && <Typography align="center" altFont>{tr('updatePassword.invalidToken')}</Typography>}
        {currentStep ===  STEP_INVALID_RESET_ID && <Typography align="center" altFont>{tr('updatePassword.invalidResetId')}</Typography>}
        <div style={{textAlign: 'center'}}><Link to="/signIn">{tr('general.back')}</Link></div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <CenteredPanelPage
        backgroundColor={customization.backgroundColor}
        backgroundImage={customization.backgroundImage}
        headerBar={(
          <div style={{textAlign: 'right'}}>
            <LanguageSelector language={language} onChange={changeLanguage}/>
          </div>
        )}
        topLogo={customization.topLogo}
        topLogoAlt={customization.topLogoAlt}
        topLogoInPanel={customization.topLogoInPanel}
        title={currentStep === STEP_UPDATE_FORM ? tr('updatePassword.title') : ''}
        imageAsTitle={customization.updatePassword.title.img}
        imageAsTitleAlt={customization.updatePassword.title.imgAlt}
        subtitle={currentStep === STEP_UPDATE_FORM ? tr('updatePassword.subtitle') : ''}
      >
        {currentStep === STEP_CHECK_TOKEN && getCheckingView()}
        {currentStep === STEP_HTTP_ERROR && getErrorAlert()}
        {currentStep === STEP_UPDATE_FORM && getUpdateForm()}
        {currentStep === STEP_INVALID_TOKEN && getInvalidTokenOrIdAlert()}
        {currentStep === STEP_INVALID_RESET_ID && getInvalidTokenOrIdAlert()}
        {currentStep === STEP_SUCCESSFUL_UPDATE && getUserConfirmationView()}
      </CenteredPanelPage>
    </React.Fragment>
  );
}
