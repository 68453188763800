import superagent from 'superagent';

/* export function getCaptchaKey() {
  if (window) {
    console.log('client-side captcha: ' + window.RECAPTCHA_PUBLIC_TOKEN);
    return window.RECAPTCHA_PUBLIC_TOKEN;
  } else {
    console.log('server-side captcha: ' + process.env.RECAPTCHA_SITE_KEY);
    return process.env.RECAPTCHA_SITE_KEY;
  }
}
 */

export async function verifyCaptchaToken(captchaToken) {
  // Send token to the backend to get a score from reCaptcha
  try {
    const response = await superagent.post(`/captcha/verify`).send({
      token: captchaToken
    });

    // alert(`your recaptcha token verification response => ${JSON.stringify(response.body)}`);
    return response.body.success;
  } catch (error) {
    // alert(`ERROR | your recaptcha token verification failed => ${error}`);
    return false;
  }
}
