import React, { useState, useEffect, createRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import queryString from 'query-string';

import useWidth from '../hooks/useWidth';

import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import Typography from '../components/Typography';

import ApiErrorAlert from '../components/ApiErrorAlert';
import Button from '../components/Button';
import CenteredPanelPage from '../components/CenteredPanelPage';
import LanguageSelector from '../components/LanguageSelector';
import Link from "../components/Link";
import TextField from '../components/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  // redirectToUrl,
  redirectToAccountUrl,
  SIGNIN_ACTION_ID,
  signIn,
  GET_OIDC_AUTHORIZATION_URL_ACTION_ID,
  getOIDCAuthorizationUrl,
  SIGNOUT_ACTION_ID,
  signOut,
  setCocoomAuthInfo
} from '../reducers/auth';

import { getLanguage } from '../utils/getLanguage';

import {
  REDIRECTION_TO_PAGE_PARAM
} from '../constants';

import * as cookie from '../utils/cookieManager';

import { makeStyles } from '@material-ui/core/styles';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { verifyCaptchaToken } from '../utils/handleCaptcha';
import CircularProgress from '../components/CircularProgress';


const useStyles = makeStyles(theme => {
  return {
    accountList: {
      overflow: 'auto',
      maxHeight: 300
    },
    signout: {
      textAlign: 'center',
      margin: theme.spacing(1)
    },

    signInAADContainer: {
      textAlign: 'center'
    },
    signInAADButtonImage: {
      maxWidth: 250,
      width: '100%'
    },
    signInAADDividerContainer: {
      verticalAlign: 'middle',
      marginTop: 20,
      marginBottom: 20
    },
    signInAADDivider: {
      verticalAlign: 'middle',
      display: 'inline-block',
      width: '30%'
    },
    signInAADDividerText: {
      display: 'inline-block',
      marginLeft: 10,
      marginRight: 10,
      fontWeight: 300
    }
  }
});


const propTypes = {
  emailHint: PropTypes.string,
  extraHeaders: PropTypes.object,
  noCreateAccountLink: PropTypes.bool,
  useCocoomAccountUri: PropTypes.bool
}

export default function SignIn({emailHint = '', extraHeaders = {}, noCreateAccountLink = false, useCocoomAccountUri = true, teamsContext = false, isAssociationMode = false}) {
  const [tr, i18n] = useTranslation();
  const language = getLanguage();
  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);
  const [state, setState] = useState({email: emailHint, password: ''});
  const [nextRedirection, setNextRedirection] = useState(null);
  const [forceEnterCredentials, setForceEnterCredentials] = useState(false);
  const [isCaptachaTokenVerified, setCaptchaTokenVerified] = useState(false);


  const dispatch = useDispatch();
  const working = auth.actionState === 'working';
  const formRef = createRef();
  const classes = useStyles();
  const gridWidth = useWidth();
  const history = useHistory();

  const signInExtraHeaders = {...extraHeaders, 'x-cocoom-uri': auth.cocoomAccountUri};

  useEffect(() => {
    cookie.resetCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME);

    if (window.location.search) {
      const urlParams = queryString.parse(window.location.search);

      if (window.location.search.indexOf(REDIRECTION_TO_PAGE_PARAM) > -1) {
        const redirectionUrl = urlParams[REDIRECTION_TO_PAGE_PARAM];
        if (redirectionUrl) {
          cookie.setCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME, redirectionUrl, 900);
        }
        setNextRedirection(redirectionUrl);
      }

      if (window.location.search.indexOf('prompt') > -1) {
        const promptForCredentials = urlParams['prompt'];
        setForceEnterCredentials(promptForCredentials);
      }
    }

    if (auth.action === SIGNIN_ACTION_ID && auth.actionState === 'done') {
      cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);

      if (window.location.search && window.location.search.indexOf(REDIRECTION_TO_PAGE_PARAM) > 0) {

        const urlParams = queryString.parse(window.location.search);
        dispatch(redirectToAccountUrl(urlParams[REDIRECTION_TO_PAGE_PARAM]));
        return;
      }

      if (useCocoomAccountUri && auth.cocoomAccountUri) {
        dispatch(redirectToAccountUrl(auth.cocoomAccountUri));
        return;
      }
    }
  }, [dispatch, useCocoomAccountUri, auth.action, auth.actionState, auth.user, auth.cocoomAccountUri]);

  useEffect(() => {
    if (!isAssociationMode) {
      cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
    }
  }, [isAssociationMode]);

  // Reset password but not email field when sign in action failed
  useEffect(() => {
    if (auth.action === SIGNIN_ACTION_ID && auth.actionState === 'failed') {
      setState({email: state.email, password: ''});
    }
  }, [auth.action, auth.actionState, state.email]);


  useEffect(() => {
    if (formRef.current) {
      formRef.current.reset();
    }
  }, [formRef]);


  useEffect(() => {
    const listener = event => {
      if (state.email && state.password && (event.code === "Enter" || event.code === "NumpadEnter")) {
        dispatch(signIn({email: state.email, password: state.password, extraHeaders: signInExtraHeaders}));
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [dispatch, state.email, state.password, signInExtraHeaders]);

  useEffect(() => {
    if (auth.user && auth.user.accounts && auth.user.accounts.length === 1) {
      dispatch(redirectToAccountUrl(auth.user.accounts[0].url));
    }
  }, [dispatch, auth.user]);


  function changeLanguage(language) {
    i18n.changeLanguage(language);
  };

  function onChangeCredentialFieldValue(changeEvent, type) {
    const newState = {...state};
    newState[type] = changeEvent.target.value; // "type" could be either 'email' or 'password'
    setState(newState);
  }

  const onAADSigninButtonClick = useCallback((dataToSend, promptForCredentials = false) => {
    dispatch(setCocoomAuthInfo('oidc'));
    dispatch(getOIDCAuthorizationUrl(dataToSend, promptForCredentials, 'aad'))
  }, [dispatch]);

  const onLegacySigninButtonClick = useCallback((dataToSend) => {
    dispatch(setCocoomAuthInfo('legacy'));
    dispatch(signIn(dataToSend));
  }, [dispatch]);

  function onResetSigninFormOutsideTeamsButtonClick() {
    cookie.resetCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME);
    dispatch(setCocoomAuthInfo('legacy'));

    window.location.replace('/signin');
  }

  function onRequestAccess() {
    history.push('/accessRequest' + (window.location.search ? window.location.search : ''));
  }

  function getAccountSelector() {
    return (
      <React.Fragment>
        <List dense className={classes.accountList}>
          {auth.user.accounts.map(account => {
            const labelId = `label-${account.id}`;
            return (
              <ListItem key={account.id} button onClick={() => dispatch(redirectToAccountUrl(account.url))}>
                {/* <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    alt={`Avatar n°${value + 1}`}
                    src={`https://static.dev.cocoom.com/logos/cocoom_avatar.png`}
                  />
                </ListItemAvatar> */}
                <ListItemText id={labelId} primary={account.name} secondary={account.url}/>
              </ListItem>
            );
          })}
        </List>
        <div style={{textAlign: 'center'}}>
          <Button id="signout" label={tr('general.signout')} variant="text" onClick={() => dispatch(signOut())} loading={working}/>
        </div>
      </React.Fragment>
    );
  }

  function getErrorAlert() {
    if (auth.actionState === 'failed') {
      const statusCode = auth.actionError.statusCode;

      if (auth.action === SIGNIN_ACTION_ID) {
        if (statusCode === 401) {
          return <ApiErrorAlert message={tr('signin.authFailed')}/>
        }

        return <ApiErrorAlert statusCode={statusCode} message={auth.actionError.text} body={auth.actionError.body}/>
      }

      if (auth.action === SIGNOUT_ACTION_ID) {
        return <ApiErrorAlert statusCode={statusCode} message={auth.actionError.text} body={auth.actionError.body}/>
      }
    }

    return null;
  }

  function getMicrosoftSignInButton(customization, teamsContext, extraHeaders) {
    return (
      <div className={classes.signInAADContainer}>
        {!teamsContext &&
          <div className={classes.signInAADDividerContainer}>
            <Divider className={classes.signInAADDivider} />
            <Typography component="h3" altFont className={classes.signInAADDividerText}>{tr('signin.aadButton')}</Typography>
            <Divider className={classes.signInAADDivider} />
          </div>
        }

        <ButtonBase
          focusRipple
          disabled={working}
          style={{opacity: working ? 0.5 : 1}}
          onClick={() => onAADSigninButtonClick(extraHeaders, forceEnterCredentials)}
        >
          <img
            alt="AAD sign-in button"
            src={customization.cdnEndpointUri + '/start/ms-symbollockup_signin_dark.svg'}
            className={classes.signInAADButtonImage}
          />
        </ButtonBase>

        {teamsContext &&
          <div className={classes.signInAADDividerContainer}>
            <Divider className={classes.signInAADDivider} />
            <Typography component="h3" altFont className={classes.signInAADDividerText}>{tr('signin.aadButton')}</Typography>
            <Divider className={classes.signInAADDivider} />
          </div>
        }
      </div>
    );
  }

  function getSigninForm() {
    return (
      <React.Fragment>
        {(teamsContext && !isAssociationMode && (!auth.cocoomAccountUri || !useCocoomAccountUri)) && getMicrosoftSignInButton(customization, teamsContext, signInExtraHeaders)}

        <form ref={formRef}>
          <TextField
            id="userId"
            name="email"
            value={state.email}
            label={tr('signin.form.email.label')}
            helperText={tr('signin.form.email.helper')}
            onChange={(event) => onChangeCredentialFieldValue(event, 'email')}
            fullWidth
            color={customization.tonicColor}
            disabled={working}
            autocomplete="username"
            autoFocus
            required
          />
          <TextField
            id="userPassword"
            name="password"
            value={state.password}
            label={tr('signin.form.password.label')}
            helperText={tr('signin.form.password.helper')}
            helperTextUrl={'/forgottenPassword' + (window.location.search ? window.location.search : '')}
            onChange={(event) => onChangeCredentialFieldValue(event, 'password')}
            fullWidth
            password
            color={customization.tonicColor}
            disabled={working}
            autocomplete="current-password"
            required
          />
          <div style={{textAlign: 'center'}}>
            <div>
              <Button
                id="signin"
                label={tr('signin.form.submit')}
                color="primary"
                onClick={() => onLegacySigninButtonClick({email: state.email, password: state.password, extraHeaders: signInExtraHeaders})}
                loading={working}
                disabled={!state.email || !state.password || !isCaptachaTokenVerified}
              />
            </div>

            {!teamsContext && nextRedirection &&
              <div>
                <Button id="signintoanother" color="default"
                  label={tr('signedout.signinAnotherAccount')}
                  disabled={working || !isCaptachaTokenVerified}
                  onClick={onResetSigninFormOutsideTeamsButtonClick}
                />
              </div>
            }

          </div>
        </form>

        {(!teamsContext && (!auth.cocoomAccountUri || !useCocoomAccountUri)) && getMicrosoftSignInButton(customization)}

        {((auth.cocoomAccountUri && customization.accessRequest.accessRequestRecipient) || (!noCreateAccountLink && !auth.cocoomAccountUri)) &&
          (<div style={{textAlign: 'center'}}><div className={classes.signInAADDividerContainer}>
            <Divider className={classes.signInAADDivider} />
          </div></div>)
        }

        {auth.cocoomAccountUri && customization.accessRequest.accessRequestRecipient &&
          <div style={{textAlign: 'center'}}>
            <Typography component="span" altFont>{tr('signin.form.requestAnAccessText')}</Typography>
            <Button id="buttonToRequestAccess" variant="outlined" label={tr('signin.form.requestAnAccessButton')} onClick={() => onRequestAccess()} disabled={!isCaptachaTokenVerified} />
          </div>
        }
        {!noCreateAccountLink && !auth.cocoomAccountUri && <div style={{textAlign: 'center'}}>➡️&nbsp;<Link disabled={working} to="/signup?fsignin">{tr('signin.form.createAnAccount')}</Link></div>}
      </React.Fragment>
    );
  }

  const verifyCaptcha = useCallback(async (token) => {
    try {
      const verifiedToken = await verifyCaptchaToken(token);
      setCaptchaTokenVerified(!!verifiedToken);
    } catch (error) {
      setCaptchaTokenVerified(false);
    }
  }, []);

  const gettingOIDCCallbackUrl = (auth.action === GET_OIDC_AUTHORIZATION_URL_ACTION_ID);

  return (
    <React.Fragment>
      <CenteredPanelPage
        backgroundColor={customization.backgroundColor}
        backgroundImage={customization.backgroundImage}
        headerBar={(
          <div style={{textAlign: 'right'}}>
            <LanguageSelector language={language} onChange={changeLanguage}/>
          </div>
        )}
        topLogo={customization.topLogo}
        topLogoAlt={customization.topLogoAlt}
        topLogoInPanel={customization.topLogoInPanel}
        title={!auth.user ? customization.signIn.title[language] : `${tr('signin.hello')}${auth.user.firstname}${tr('general.exclamationMark')}`}
        imageAsTitle={!auth.user ? customization.signIn.title.img : undefined}
        imageAsTitleAlt={customization.signIn.title.imgAlt}
        subtitle={!auth.user ? customization.signIn.subtitle[language] : tr('signin.severalAccountMessage')}

        fullWidth={gridWidth === 'xs'}
      >
        {!gettingOIDCCallbackUrl &&
          <div>
            {getErrorAlert()}
            {!auth.user && !working && auth.authMode !== 'oidc' && getSigninForm()}
            {auth.user && !working && getAccountSelector()}
            {working && <div style={{textAlign: 'center'}}><CircularProgress/></div>}
          </div>
        }
        <GoogleReCaptcha onVerify={verifyCaptcha} />
      </CenteredPanelPage>
    </React.Fragment>
  );
}

SignIn.propTypes = propTypes;
