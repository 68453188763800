import React from 'react';
import { useLocation } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';


export default function BaseRoutedContainer({children}) {
  const currentPageLocation = useLocation();
  const captcha = useSelector(state => state.captcha);
  // console.log(`BaseRoutedContainer | Captacha Token = ${captcha.captchaPublicKey}`);

  React.useEffect(() => {
    if (window && window.Intercom && currentPageLocation) {
      window.Intercom('update', { current_location: currentPageLocation.pathname, time: new Date().getTime() });
    }
  }, [currentPageLocation]);

  return (
    <React.Fragment>
      <GoogleReCaptchaProvider reCaptchaKey={captcha.captchaPublicKey}>
        {children}
      </GoogleReCaptchaProvider>
    </React.Fragment>
  );
}
